import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { t } from "i18next";
import { useMemo } from "react";
import useGetComponentStatistic from "src/hooks/useGetComponentStatistic";

import PieChart from "@app-components/chart/PieChart";
import { COMPONENT_TYPES } from "@app-services/stockServices";

interface IStockStatisticChart {
  label: string;
  total: number;
  componentType: string;
  items: { label: string; total: number }[];
}

export default function DashBoardChart() {
  const { items } = useGetComponentStatistic();

  const titleMap = {
    [COMPONENT_TYPES.BOX]: t("stock.box"),
    [COMPONENT_TYPES.CHIP]: t("stock.chip"),
    [COMPONENT_TYPES.CHIP_MODULE]: t("stock.chipmodule"),
    [COMPONENT_TYPES.POUCH]: t("stock.pouch"),
    [COMPONENT_TYPES.RING_SHELL]: t("stock.ringshell"),
  };

  const componentRoutes = {
    [COMPONENT_TYPES.CHIP]: "/stock/chips",
    [COMPONENT_TYPES.RING_SHELL]: "/stock/ringshells",
    [COMPONENT_TYPES.CHIP_MODULE]: "/stock/chipmodules",
    [COMPONENT_TYPES.BOX]: "/stock/boxes",
    [COMPONENT_TYPES.POUCH]: "/stock/pouches",
  };

  const stockChartData = useMemo(
    () =>
      Object.values(
        items?.reduce((rs, itm) => {
          rs[itm.componentType] = {
            ...(rs?.[itm.componentType] || {}),
            label: titleMap[itm.componentType],
            componentType: itm.componentType,
            total:
              (rs?.[itm.componentType]?.total || 0) + Number(itm.total || 0),

            items: [
              ...(rs?.[itm.componentType]?.items || []),
              { ...itm, label: itm.componentSpec, total: Number(itm.total) },
            ],
          };
          return rs;
        }, {}) as IStockStatisticChart[],
      ) || [],
    [items],
  );

  return (
    <div>
      <div className="flex flex-col gap-4 p-6 bg-white rounded-xl mt-6">
        <div className="font-bold text-3xl text-zinc-500">
          {t("title.stockManagement")}
        </div>
        <div className="flex justify-center gap-4">
          <div className="w-full min-[300px] max-w-[400px]">
            <PieChart
              data={stockChartData}
              label={t("stock.quantity")}
              title={t("stock.allComponents")}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap mt-4 gap-4 justify-center">
          {stockChartData?.map((itm, idx) => (
            <div key={idx} className="w-full min-[200px] max-w-[300px]">
              <PieChart
                key={idx}
                data={itm.items}
                label={t("stock.quantity")}
                title={itm.label}
                href={componentRoutes[itm.componentType]}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
