import { Form, InputRef, Select, Table, message } from "antd";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DEFAULT_METADATA } from "@app-constants/common";
import { IResponseData } from "@app-interface/common";
import { IChip } from "@app-interface/stock";
import stockServices, { COMPONENT_TYPES } from "@app-services/stockServices";

import AddOrEditStockItemModal from "../components/AddOrEditStockItemModal";
import { getColumnSearchProps } from "../components/FilteredTable";
import StockControls from "../components/StockControls";
import StockSummary from "../components/StockSummary";

export default function ChipsPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const summaryRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState<IChip | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [updateType, setUpdateType] = useState<"output" | "input">("input");

  const [searchParams, setSearchParams] = useState<null | {
    [key: string]: string;
  }>();
  const searchInput = useRef<InputRef>(null);
  const [data, setData] = useState<IResponseData<IChip>>(DEFAULT_METADATA);

  useEffect(() => {
    handleGetItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams) {
      handleGetItems();
    }
  }, [searchParams]);

  const handleGetItems = async (metadata?: any) => {
    setLoading(true);
    try {
      const response = await stockServices.getChips({
        ...(metadata || data.metadata),
        ...searchParams,
      });
      setData(response);
    } catch (error) {
      message.error(t(error as string));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: data.metadata?.limit,
      offset: (tableData.current - 1) * +data?.metadata?.limit,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetItems(newMetadata);
  };

  const handleModalCancel = () => {
    setItemData(null);
    setOpenModal(false);
  };

  const handleSuccess = () => {
    handleGetItems();
    summaryRef?.current?.onRefresh?.();
    handleModalCancel();
  };

  const handleSearch = (
    selectedKeys: string[],
    close: any,
    dataIndex: any,
    confirm: any,
  ) => {
    confirm();
    if (selectedKeys[0]) {
      setSearchParams((prev) => ({
        ...prev,
        [dataIndex]: selectedKeys.length > 1 ? selectedKeys : selectedKeys[0],
      }));
    }
    close();
  };

  const handleReset = (
    clearFilters: () => void,
    dataIndex: string,
    confirm: any,
    close: any,
  ) => {
    setSearchParams((prev) => {
      const data = Object.assign({}, prev);
      if (data[dataIndex]) {
        data[dataIndex] = undefined;
      }
      return data;
    });
    clearFilters();
    confirm();
    close();
  };

  const handleSave = async (data: Partial<IChip>) => {
    if (itemData?.id) {
      return stockServices.updateChip(itemData.id, data);
    } else {
      return stockServices.createChip(data);
    }
  };

  const handleClear = () => {
    setSearchParams({});
    setData((prev) => ({ ...prev, ...DEFAULT_METADATA }));
  };

  const chipTypeOptions = [
    { label: "Visa", value: "VISA" },
    { label: "Mastercard", value: "MASTERCARD" },
    { label: "Napas", value: "NAPAS" },
    { label: "Payment", value: "PAYMENT" },
    { label: "Access Control", value: "ACCESS_CONTROL" },
  ];

  const handleUpdateOutput = () => {
    setUpdateType("output");
    setOpenModal(true);
  };

  const handleUpdateInput = () => {
    setUpdateType("input");
    setOpenModal(true);
  };

  return (
    <div>
      {contextHolder}
      <StockControls
        onClear={handleClear}
        onUpdateInput={handleUpdateInput}
        onUpdateOutput={handleUpdateOutput}
        title={t("stock.chips")}
        name={t("stock.chip")}
      />
      <StockSummary
        ref={summaryRef}
        title={t("stock.totalRingShells")}
        type={COMPONENT_TYPES.CHIP}
      />
      <Table<IChip>
        rowKey={(row) => row.id?.toString() ?? ""}
        loading={loading}
        columns={[
          {
            title: t("stock.chipType"),
            dataIndex: "componentSpec",
            key: "componentSpec",
            ...getColumnSearchProps(
              "componentSpec",
              searchInput,
              handleSearch,
              handleReset,
              chipTypeOptions,
              searchParams,
            ),
          },

          {
            title: t("stock.quantity"),
            dataIndex: "quantity",
            key: "quantity",
          },
          {
            title: t("stock.purpose"),
            dataIndex: "purpose",
            key: "purpose",
          },
          {
            title: t("stock.note"),
            dataIndex: "note",
            key: "note",
          },
          {
            title: t("stock.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: t("stock.updatedAt"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
        ]}
        dataSource={data?.models as any}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        pagination={{
          total: +data?.metadata?.count,
          pageSize: +data?.metadata?.limit,
          current: +data?.metadata?.offset / +data?.metadata?.limit + 1,
        }}
      />
      {openModal && (
        <AddOrEditStockItemModal
          isOpen={openModal}
          itemData={itemData}
          onSuccess={handleSuccess}
          onCancel={handleModalCancel}
          title={t("stock.chip")}
          onSave={handleSave}
          componentType={COMPONENT_TYPES.CHIP}
          type={updateType}
          extraFields={
            <>
              <Form.Item
                name="componentSpec"
                label={t("stock.chipType")}
                rules={[
                  { required: true, message: t("form.requiredField.required") },
                ]}
              >
                <Select options={chipTypeOptions} />
              </Form.Item>
              {/* <Form.Item name="serialNo" label={t("stock.serialNo")}>
              <Input placeholder={t("form.common.placeholder")} />
            </Form.Item> */}
            </>
          }
        />
      )}
    </div>
  );
}
