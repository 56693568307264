import { Button, Space } from "antd";
import { t } from "i18next";

interface StockControlsProps {
  onUpdateInput: () => void;
  onUpdateOutput: () => void;
  onClear?: () => void;
  title: string;
  name: string;
}

export default function StockControls({
  onUpdateInput,
  onUpdateOutput,
  onClear,
  title,
  name,
}: StockControlsProps) {
  return (
    <div className="flex justify-between items-center mb-4">
      <h1 className="text-2xl font-bold">{title}</h1>
      <Space>
        <Button type="primary" onClick={onUpdateInput}>
          {t("button.input")} {name}
        </Button>
        <Button type="primary" danger onClick={onUpdateOutput}>
          {t("button.output")} {name}
        </Button>
        {onClear && (
          <Button type="dashed" onClick={onClear}>
            {t("button.clearFilter")}
          </Button>
        )}
      </Space>
    </div>
  );
}
