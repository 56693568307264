export const CHART_COLORS = [
  "#0D1B2A", // Dark Navy Blue
  "#1B263B", // Deep Space Blue
  "#2C3E50", // Dark Blue
  "#34495E", // Charcoal Blue
  "#4A6D7C", // Slate Blue
  "#5D6D7E", // Dusty Blue
  "#6C7A89", // Blue Gray
  "#7FB3D5", // Light Steel Blue
  "#85C1AE", // Light Teal
  "#1F3A64", // Midnight Blue
  "#2C5D84", // Blue Ocean
  "#3A6997", // Steel Blue
  "#5D8AA8", // Air Force Blue
  "#4F81BD", // Azure Blue
  "#4682B4", // Steel Blue
  "#5D6D7E", // Dusty Blue (again for emphasis)
  "#6D7F8C", // Twilight Blue
  "#2A4567", // Sapphire Blue
  "#1D2951", // Midnight Navy
  "#003366", // Dark Azure
  "#1E3A8A", // Dark Royal Blue
  "#003B5C", // Deep Blue
  "#2C7BB5", // Bright Navy Blue
  "#006699", // Vivid Blue
  "#4169E1", // Royal Blue
  "#5B7D9C", // Blue Ridge
  "#3E5C76", // Navy Blue
  "#2A4D6A", // Stormy Blue
  "#6A4C9C", // Dark Purple Blue (Blue-Violet)
];
