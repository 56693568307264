import { message } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { componentStatisticAtom } from "@app-jotai/component";
import useService from "@app-services/shared/use_service";
import stockServices, { COMPONENT_TYPES } from "@app-services/stockServices";

const useGetStatistic = () => useService(stockServices.getStatistic, []);

export default function useGetComponentStatistic(type?: COMPONENT_TYPES) {
  const [loading, setLoading] = useState<boolean>(true);
  const [itemPayload, onGetItem, clearItemPayload] = useGetStatistic();
  const [statistics, setStatistics] = useAtom(componentStatisticAtom);

  const { t } = useTranslation();

  useEffect(() => {
    onGetItem(type);
  }, [type]);

  useEffect(() => {
    if (itemPayload.success && type) {
      if (type) {
        let itemsMap = {};
        let total = 0;

        itemPayload?.data?.forEach((itm) => {
          total = total + Number(itm.total);
          itemsMap = { ...itemsMap, [itm.componentSpec]: Number(itm.total) };
        });
        setStatistics({
          ...statistics,
          [type]: { total, items: itemPayload?.data || [], itemsMap },
        });
      }

      setLoading(false);
    }
  }, [itemPayload.success]);

  useEffect(() => {
    if (itemPayload.error) {
      message.error(t(itemPayload.error));
      setLoading(false);
      onClearItemData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPayload.error]);

  const onClearItemData = () => {
    clearItemPayload();
    setLoading(false);
  };

  const onRefresh = () => {
    setLoading(true);
    onClearItemData();
    onGetItem(type);
  };

  return { items: itemPayload?.data || [], loading, onRefresh };
}
