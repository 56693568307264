import { CHART_COLORS } from "@app-constants/color";

export const pickRandomColors = (n: number) => {
  if (n <= 0) {
    return [];
  }
  if (n > CHART_COLORS.length) {
    return CHART_COLORS;
  }

  let availableColors = [...CHART_COLORS];
  let pickedColors = [];

  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * availableColors.length);
    const selectedColor = availableColors.splice(randomIndex, 1)[0];
    pickedColors.push(selectedColor);
  }

  return pickedColors;
};
