import { atom } from "jotai";

import { IComponentStatistic } from "@app-interface/stock";
import { COMPONENT_TYPES } from "@app-services/stockServices";

interface IStatistic {
  total: number;
  items: IComponentStatistic[];
  itemsMap?: { [key: string]: number };
}

interface IComponentStatisticAtom {
  [key: string]: IStatistic;
}
const componentStatisticAtom = atom<IComponentStatisticAtom>({
  [COMPONENT_TYPES.BOX]: {
    total: 0,
    items: [],
    itemsMap: {},
  },
  [COMPONENT_TYPES.CHIP]: {
    total: 0,
    items: [],
    itemsMap: {},
  },
  [COMPONENT_TYPES.CHIP_MODULE]: {
    total: 0,
    items: [],
    itemsMap: {},
  },
  [COMPONENT_TYPES.POUCH]: {
    total: 0,
    items: [],
  },
  [COMPONENT_TYPES.RING]: {
    total: 0,
    items: [],
    itemsMap: {},
  },
  [COMPONENT_TYPES.RING_SHELL]: {
    total: 0,
    items: [],
    itemsMap: {},
  },
});

export { componentStatisticAtom };
