export const LocalStorageKeys = {
  CMS_SAVED_ACCOUNT: "CMS_SAVED_ACCOUNT",
  CMS_TOKEN: "CMS_TOKEN",
  PERMISSION_KEY: "PERMISSION_KEY",
  PERMISSION_KEY_EXPIRE_AT: "PERMISSION_KEY_EXPIRE_AT",
};

export const isGBAVent = true;

export const DEFAULT_METADATA = {
  metadata: {
    limit: 20,
    offset: 0,
    count: 0,
  },
};

export const RING_SIZE_OPTIONS = [
  { label: "Size 5", value: "5" },
  { label: "Size 6", value: "6" },
  { label: "Size 7", value: "7" },
  { label: "Size 8", value: "8" },
  { label: "Size 9", value: "9" },
  { label: "Size 10", value: "10" },
  { label: "Size 11", value: "11" },
  { label: "Size 12", value: "12" },
  { label: "Size 13", value: "13" },
  { label: "Size 14", value: "14" },
  { label: "Size 15", value: "15" },
];
