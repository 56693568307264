import { t } from "i18next";
import { useAtomValue } from "jotai";
import { forwardRef, useImperativeHandle } from "react";
import useGetComponentStatistic from "src/hooks/useGetComponentStatistic";

import { componentStatisticAtom } from "@app-jotai/component";
import { COMPONENT_TYPES } from "@app-services/stockServices";

interface StockSummaryProps {
  type: COMPONENT_TYPES;
  title: string;
  unit?: string;
  isSingle?: boolean;
}

export default forwardRef(function StockSummary(
  { type, title, unit, isSingle }: StockSummaryProps,
  ref,
) {
  const { onRefresh } = useGetComponentStatistic(type);
  const componentStatistics = useAtomValue(componentStatisticAtom);

  useImperativeHandle(ref, () => ({ onRefresh }));

  return (
    <div className="p-6 flex flex-col gap-4 rounded-lg bg-white my-2 shadow-sm">
      <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-start">
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-gray-500 text-lg">
            {title} ({t("content.pcs")})
          </span>
          <span className="ml-2 font-black text-primary-color text-4xl">
            {componentStatistics[type].total}
          </span>
        </div>
        {!isSingle && (
          <div className="flex-1 flex flex-row gap-4 justify-center">
            {componentStatistics[type].items?.map((itm, idx) => (
              <div
                key={idx}
                className="rounded-sm flex-1 max-w-[150px] flex flex-col px-2 py-[2px] items-center"
              >
                <span className="font-black text-2xl">{itm.total}</span>
                <hr />
                <span className="font-semibold text-gray-500 text-lg">
                  {unit} {itm.componentSpec}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
