import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";

import { pickRandomColors } from "@app-utils/color";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

interface Props {
  data?: { label: string; total: number }[];
  title: string;
  label: string;
  href?: string;
}

export default function PieChart({ data, title, label, href }: Props) {
  const chartData = useMemo(() => {
    const colors = pickRandomColors(data.length);

    return {
      labels: data.map((itm) => itm.label || ""),
      datasets: [
        {
          label: label,
          data: data.map((itm) => itm.total),
          backgroundColor: colors,
          borderWidth: 2,
          borderColor: ["#F2F2F3"],
        },
      ],
    };
  }, [data]);

  return (
    <div className="flex justify-center flex-col gap-3">
      <div className="text-center font-bold text-2xl text-zinc-500">
        {href ? <Link to={href}>{title}</Link> : title}
      </div>
      <Pie
        data={chartData}
        options={{
          plugins: {
            legend: {
              fullSize: true,
              labels: {
                font: {
                  size: 14,
                  weight: 500,
                },
              },
            },
            tooltip: {
              padding: 16,
              titleFont: {
                size: 16,
                weight: 500,
              },
              bodyFont: {
                size: 14,
                weight: 500,
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data: number) => {
                  sum = sum + data;
                });
                let percentage = ((value * 100) / sum).toFixed(2);
                return percentage + "%";
              },
              color: "#FFF",
              font: {
                size: 14,
                weight: 500,
              },
            },
          },
        }}
      />
    </div>
  );
}
