import { Table, message } from "antd";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IResponseData } from "@app-interface/common";
import { IBox } from "@app-interface/stock";
import stockServices, { COMPONENT_TYPES } from "@app-services/stockServices";

import AddOrEditStockItemModal from "../components/AddOrEditStockItemModal";
import StockControls from "../components/StockControls";
import StockSummary from "../components/StockSummary";

export default function BoxesPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const summaryRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState<IBox | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [updateType, setUpdateType] = useState<"output" | "input">("input");

  const [data, setData] = useState<IResponseData<IBox>>({
    metadata: {
      limit: 20,
      offset: 0,
      count: 0,
    },
  });

  useEffect(() => {
    handleGetItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetItems = async (metadata?: any) => {
    setLoading(true);
    try {
      const response = await stockServices.getBoxes({
        ...(metadata || data.metadata),
      });
      setData(response);
    } catch (error) {
      message.error(t(error as string));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: data.metadata?.limit,
      offset: (tableData.current - 1) * +data?.metadata?.limit,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetItems(newMetadata);
  };

  const handleModalCancel = () => {
    setItemData(null);
    setOpenModal(false);
  };

  const handleSuccess = () => {
    handleGetItems();
    summaryRef?.current?.onRefresh?.();
    handleModalCancel();
  };

  const handleSave = async (data: Partial<IBox>) => {
    if (itemData?.id) {
      return stockServices.updateBox(itemData.id, data);
    } else {
      return stockServices.createBox(data);
    }
  };

  const handleUpdateOutput = () => {
    setUpdateType("output");
    setOpenModal(true);
  };

  const handleUpdateInput = () => {
    setUpdateType("input");
    setOpenModal(true);
  };
  return (
    <div>
      {contextHolder}
      <StockControls
        onUpdateInput={handleUpdateInput}
        onUpdateOutput={handleUpdateOutput}
        title={t("stock.ringshells")}
        name={t("stock.ringshell")}
      />
      <StockSummary
        ref={summaryRef}
        title={t("stock.totalBoxes")}
        isSingle
        type={COMPONENT_TYPES.BOX}
      />
      <Table<IBox>
        rowKey={(row) => row.id?.toString() ?? ""}
        loading={loading}
        columns={[
          {
            title: t("stock.quantity"),
            dataIndex: "quantity",
            key: "quantity",
          },
          {
            title: t("stock.purpose"),
            dataIndex: "purpose",
            key: "purpose",
          },
          {
            title: t("stock.note"),
            dataIndex: "note",
            key: "note",
          },
          {
            title: t("stock.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: t("stock.updatedAt"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
        ]}
        dataSource={data?.models as any}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        pagination={{
          total: +data?.metadata?.count,
          pageSize: +data?.metadata?.limit,
          current: +data?.metadata?.offset / +data?.metadata?.limit + 1,
        }}
      />
      {openModal && (
        <AddOrEditStockItemModal
          isOpen={openModal}
          itemData={itemData}
          onSuccess={handleSuccess}
          onCancel={handleModalCancel}
          title={t("stock.box")}
          onSave={handleSave}
          componentType={COMPONENT_TYPES.BOX}
          type={updateType}
        />
      )}
    </div>
  );
}
