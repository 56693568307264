import { Table, message } from "antd";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DEFAULT_METADATA } from "@app-constants/common";
import { IResponseData } from "@app-interface/common";
import { IPouch } from "@app-interface/stock";
import stockServices, { COMPONENT_TYPES } from "@app-services/stockServices";

import AddOrEditStockItemModal from "../components/AddOrEditStockItemModal";
import StockControls from "../components/StockControls";
import StockSummary from "../components/StockSummary";

export default function PouchesPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const summaryRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState<IPouch | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [updateType, setUpdateType] = useState<"output" | "input">("input");

  const [data, setData] = useState<IResponseData<IPouch>>(DEFAULT_METADATA);

  useEffect(() => {
    handleGetItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetItems = async (metadata?: any) => {
    setLoading(true);
    try {
      const response = await stockServices.getPouches({
        ...(metadata || data.metadata),
      });
      setData(response);
    } catch (error) {
      message.error(t(error as string));
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (tableData: any) => {
    const newMetadata = {
      limit: data.metadata?.limit,
      offset: (tableData.current - 1) * +data?.metadata?.limit,
    };
    setData((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        ...newMetadata,
      },
    }));
    handleGetItems(newMetadata);
  };

  const handleModalCancel = () => {
    setItemData(null);
    setOpenModal(false);
  };

  const handleSuccess = () => {
    handleGetItems();
    summaryRef?.current?.onRefresh?.();
    handleModalCancel();
  };

  const handleSave = async (data: Partial<IPouch>) => {
    if (itemData?.id) {
      return stockServices.updatePouch(itemData.id, data);
    } else {
      return stockServices.createPouch(data);
    }
  };

  const handleUpdateOutput = () => {
    setUpdateType("output");
    setOpenModal(true);
  };

  const handleUpdateInput = () => {
    setUpdateType("input");
    setOpenModal(true);
  };

  return (
    <div>
      {contextHolder}
      <StockControls
        onUpdateInput={handleUpdateInput}
        onUpdateOutput={handleUpdateOutput}
        title={t("stock.pouches")}
        name={t("stock.pouch")}
      />
      <StockSummary
        ref={summaryRef}
        title={t("stock.totalPouches")}
        type={COMPONENT_TYPES.POUCH}
        isSingle
      />
      <Table<IPouch>
        rowKey={(row) => row.id?.toString() ?? ""}
        loading={loading}
        columns={[
          {
            title: t("stock.quantity"),
            dataIndex: "quantity",
            key: "quantity",
          },
          {
            title: t("stock.purpose"),
            dataIndex: "purpose",
            key: "purpose",
          },
          {
            title: t("stock.note"),
            dataIndex: "note",
            key: "note",
          },
          {
            title: t("stock.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
          {
            title: t("stock.updatedAt"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data) => <div>{format(new Date(data), "PPp")}</div>,
          },
        ]}
        dataSource={data?.models as any}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        pagination={{
          total: +data?.metadata?.count,
          pageSize: +data?.metadata?.limit,
          current: +data?.metadata?.offset / +data?.metadata?.limit + 1,
        }}
      />

      {openModal && (
        <AddOrEditStockItemModal
          isOpen={openModal}
          itemData={itemData}
          onSuccess={handleSuccess}
          onCancel={handleModalCancel}
          title={t("stock.pouch")}
          onSave={handleSave}
          componentType={COMPONENT_TYPES.POUCH}
          type={updateType}
        />
      )}
    </div>
  );
}
