import { DatePicker, Statistic } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from "react";

import DashBoardChart from "@app-components/chart/DashBoardChart";
import useService from "@app-services/shared/use_service";
import statisticService from "@app-services/statisticServices";

dayjs.extend(customParseFormat);

const useStatistics = () => useService(statisticService.getStatistic);

export default function DashboardPage() {
  const [{ data }, getStatistics] = useStatistics();
  const [date, setDate] = useState([dayjs().subtract(7, "days"), dayjs()]);

  useEffect(() => {
    getStatistics({
      start: date?.[0]?.toISOString(),
      end: date?.[1]?.toISOString(),
    });
  }, [date, getStatistics]);

  const onChange: any = (date: any, dateString: string) => {
    console.log(date, dateString);
    setDate(date);
  };

  if (!data) return null;

  return (
    <div>
      <DatePicker.RangePicker
        onChange={onChange}
        defaultValue={[dayjs().subtract(7, "days"), dayjs()]}
        value={date as any}
      />
      <div className="flex gap-4 [&>div]:flex-1 pt-4">
        <div className="p-4 rounded-lg bg-white shadow-sm">
          <Statistic title="Total Users" value={data.userCount} />
        </div>
        <div className="p-4 rounded-md bg-white shadow-sm">
          <Statistic title="Paired Rings" value={data.itemCount} />
        </div>
        <div className="p-4 rounded-md bg-white shadow-sm">
          <Statistic title="Orders Created" value={data.orderCount} />
        </div>
        <div className="p-4 rounded-md bg-white shadow-sm">
          <Statistic title="Total Ring Orders" value={data.orderItemCount} />
        </div>
      </div>
      <DashBoardChart />
    </div>
  );
}
