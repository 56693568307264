import { Form, Input, InputNumber, Modal, message } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IStockItem } from "@app-interface/stock";
import { componentStatisticAtom } from "@app-jotai/component";
import { COMPONENT_TYPES } from "@app-services/stockServices";

interface AddOrEditStockItemModalProps {
  isOpen: boolean;
  itemData: IStockItem | null;
  onSuccess: () => void;
  onCancel: () => void;
  title: string;
  onSave: (data: Partial<IStockItem>) => Promise<any>;
  extraFields?: React.ReactNode;
  componentType: COMPONENT_TYPES;
  type: "output" | "input";
}

export default function AddOrEditStockItemModal({
  isOpen,
  itemData,
  onSuccess,
  onCancel,
  title,
  onSave,
  extraFields,
  type,
  componentType,
}: AddOrEditStockItemModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const statistics = useAtomValue(componentStatisticAtom);

  useEffect(() => {
    if (isOpen && itemData) {
      form.setFieldsValue({
        name: itemData.name,
        quantity: itemData.quantity,
        description: itemData.description,
        status: itemData.status,
        ...itemData,
      });
    } else {
      form.resetFields();
    }
  }, [form, isOpen, itemData]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      if (type === "output") {
        values.quantity = values.quantity * -1;
      }

      await onSave(values);

      message.success(
        itemData
          ? t("message.updateSuccess", { item: title })
          : t("message.createSuccess", { item: title }),
      );
      onSuccess();
    } catch (error: any) {
      message.error(t(error as string));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`${t(`button.${type}`)} ${title}`}
      open={isOpen}
      onOk={handleSubmit}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical">
        {extraFields}
        <Form.Item
          name="quantity"
          label={t("stock.quantity")}
          rules={[
            { required: true, message: t("form.requiredField.required") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const spec = getFieldValue("componentSpec");

                if (type == "input" && value > 0) {
                  return Promise.resolve();
                }

                if (value <= 0) {
                  return Promise.reject(
                    new Error(t("form.quantity.numberRule")),
                  );
                }

                if (spec) {
                  const specVal = statistics[componentType]?.itemsMap?.[spec];
                  if (value - specVal <= 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t("form.quantity.numberRuleOutput", {
                        value: specVal,
                      }),
                    ),
                  );
                }

                return value - statistics[componentType]?.total <= 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        t("form.quantity.numberRuleOutput", {
                          value: statistics[componentType]?.total,
                        }),
                      ),
                    );
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="purpose" label={t("stock.purpose")}>
          <Input.TextArea rows={3} placeholder={t("form.common.placeholder")} />
        </Form.Item>

        <Form.Item name="note" label={t("stock.description")}>
          <Input.TextArea rows={3} placeholder={t("form.common.placeholder")} />
        </Form.Item>

        {/* <Form.Item
          name="status"
          label={t("stock.status")}
          rules={[{ required: true, message: t("form.requiredField.required") }]}
        >
          <Select>
            <Select.Option value="AVAILABLE">{t("stock.status_available")}</Select.Option>
            <Select.Option value="LOW_STOCK">{t("stock.status_low_stock")}</Select.Option>
            <Select.Option value="OUT_OF_STOCK">{t("stock.status_out_of_stock")}</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
}
