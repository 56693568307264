import { IResponseData } from "../interface/common";
import {
  IBox,
  IChip,
  IChipModule,
  IComponentStatistic,
  IPouch,
  IRingShell,
  IStockItem,
} from "../interface/stock";
import { baseApi } from "./common";

export enum COMPONENT_TYPES {
  CHIP = "CHIP",
  RING_SHELL = "RING_SHELL",
  CHIP_MODULE = "CHIP_MODULE",
  BOX = "BOX",
  POUCH = "POUCH",
  RING = "RING",
}

const stockServices = {
  // Generic methods
  getStockItems: async (
    type: string,
    metadata: any,
  ): Promise<IResponseData<IStockItem>> => {
    const response = await baseApi.get(`/admin/components`, {
      params: { ...metadata, componentType: type },
    });
    return response.data;
  },

  createStockItem: async (
    type: string,
    data: Partial<IStockItem>,
  ): Promise<IStockItem> => {
    const response = await baseApi.post(`/admin/components`, {
      ...data,
      componentType: type,
    });
    return response.data;
  },

  updateStockItem: async (
    type: string,
    id: number,
    data: Partial<IStockItem>,
  ): Promise<IStockItem> => {
    const response = await baseApi.patch(`/admin/components/${id}`, data);
    return response.data;
  },

  deleteStockItem: async (type: string, id: number): Promise<boolean> => {
    const response = await baseApi.delete(`/stock/${type}/${id}`);
    return response.data;
  },

  // Type-specific methods
  getChips: async (metadata: any): Promise<IResponseData<IChip>> => {
    return stockServices.getStockItems(
      COMPONENT_TYPES.CHIP,
      metadata,
    ) as Promise<IResponseData<IChip>>;
  },

  getRingShells: async (metadata: any): Promise<IResponseData<IRingShell>> => {
    return stockServices.getStockItems(
      COMPONENT_TYPES.RING_SHELL,
      metadata,
    ) as Promise<IResponseData<IRingShell>>;
  },

  getChipModules: async (
    metadata: any,
  ): Promise<IResponseData<IChipModule>> => {
    return stockServices.getStockItems(
      COMPONENT_TYPES.CHIP_MODULE,
      metadata,
    ) as Promise<IResponseData<IChipModule>>;
  },

  getBoxes: async (metadata: any): Promise<IResponseData<IBox>> => {
    return stockServices.getStockItems(
      COMPONENT_TYPES.BOX,
      metadata,
    ) as Promise<IResponseData<IBox>>;
  },

  getPouches: async (metadata: any): Promise<IResponseData<IPouch>> => {
    return stockServices.getStockItems(
      COMPONENT_TYPES.POUCH,
      metadata,
    ) as Promise<IResponseData<IPouch>>;
  },

  // Create methods for specific types
  createChip: async (data: Partial<IChip>): Promise<IChip> => {
    return stockServices.createStockItem(
      COMPONENT_TYPES.CHIP,
      data,
    ) as Promise<IChip>;
  },

  createRingShell: async (data: Partial<IRingShell>): Promise<IRingShell> => {
    return stockServices.createStockItem(
      COMPONENT_TYPES.RING_SHELL,
      data,
    ) as Promise<IRingShell>;
  },

  createChipModule: async (
    data: Partial<IChipModule>,
  ): Promise<IChipModule> => {
    return stockServices.createStockItem(
      COMPONENT_TYPES.CHIP_MODULE,
      data,
    ) as Promise<IChipModule>;
  },

  createBox: async (data: Partial<IBox>): Promise<IBox> => {
    return stockServices.createStockItem(
      COMPONENT_TYPES.BOX,
      data,
    ) as Promise<IBox>;
  },

  createPouch: async (data: Partial<IPouch>): Promise<IPouch> => {
    return stockServices.createStockItem(
      COMPONENT_TYPES.POUCH,
      data,
    ) as Promise<IPouch>;
  },

  // Update methods for specific types
  updateChip: async (id: number, data: Partial<IChip>): Promise<IChip> => {
    return stockServices.updateStockItem(
      COMPONENT_TYPES.CHIP,
      id,
      data,
    ) as Promise<IChip>;
  },

  updateRingShell: async (
    id: number,
    data: Partial<IRingShell>,
  ): Promise<IRingShell> => {
    return stockServices.updateStockItem(
      COMPONENT_TYPES.RING_SHELL,
      id,
      data,
    ) as Promise<IRingShell>;
  },

  updateChipModule: async (
    id: number,
    data: Partial<IChipModule>,
  ): Promise<IChipModule> => {
    return stockServices.updateStockItem(
      COMPONENT_TYPES.CHIP_MODULE,
      id,
      data,
    ) as Promise<IChipModule>;
  },

  updateBox: async (id: number, data: Partial<IBox>): Promise<IBox> => {
    return stockServices.updateStockItem(
      COMPONENT_TYPES.BOX,
      id,
      data,
    ) as Promise<IBox>;
  },

  updatePouch: async (id: number, data: Partial<IPouch>): Promise<IPouch> => {
    return stockServices.updateStockItem(
      COMPONENT_TYPES.POUCH,
      id,
      data,
    ) as Promise<IPouch>;
  },

  // Delete methods for specific types
  deleteChip: async (id: number): Promise<boolean> => {
    return stockServices.deleteStockItem(COMPONENT_TYPES.CHIP, id);
  },

  deleteRingShell: async (id: number): Promise<boolean> => {
    return stockServices.deleteStockItem(COMPONENT_TYPES.RING_SHELL, id);
  },

  deleteChipModule: async (id: number): Promise<boolean> => {
    return stockServices.deleteStockItem(COMPONENT_TYPES.CHIP_MODULE, id);
  },

  deleteBox: async (id: number): Promise<boolean> => {
    return stockServices.deleteStockItem(COMPONENT_TYPES.BOX, id);
  },

  deletePouch: async (id: number): Promise<boolean> => {
    return stockServices.deleteStockItem(COMPONENT_TYPES.POUCH, id);
  },

  getStatistic: async (type: string): Promise<IComponentStatistic[]> => {
    const response = await baseApi.get(`/admin/components/statistic`, {
      params: { componentType: type },
    });
    return response.data;
  },
};

export default stockServices;
